<template>
  <div :class="$style.assortments">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск по названию"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectAssortment"
          @handleFindItems="handleFindAssortments"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-cascader
        v-model="categoryId"
        :options="categories"
        :props="{
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
        }"
        style="width: 30rem"
        collapse-tags
        filterable
        clearable
        placeholder="Поиск по категории"
        @change="getList"
      />
      <div :class="$style.checkBoxWrapper">
        <label>Рекламные</label>
        <el-checkbox
          :checked="isPromotional"
          @change=";(isPromotional = $event), getList()"
        />
      </div>
      <div :class="$style.checkBoxWrapper">
        <label>Активные</label>
        <el-checkbox
          :checked="isActive"
          @change=";(isActive = $event), getList()"
        />
      </div>
      <div :class="$style.checkBoxWrapper">
        <label>В каталоге</label>
        <el-checkbox
          :checked="isInCatalog"
          @change=";(isInCatalog = $event), getList()"
        />
      </div>

      <div :class="$style.createButton">
        <el-button
          type="primary"
          @click="
            $router.push(
              $options.ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CREATE,
            )
          "
        >
          Создать
        </el-button>
      </div>
    </div>
    <el-table :data="assortments" stripe>
      <el-table-column prop="orderField" label="Приоритет" width="120">
      </el-table-column>
      <el-table-column label="Назначен категории" width="280">
        <template slot-scope="scope">
          <div v-if="scope.row.category">
            <router-link
              :to="
                getRoute({
                  route:
                    $options.ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.CATEGORY,
                  params: { id: scope.row.category.id },
                })
              "
              :class="$style.catalogLink"
            >
              {{ scope.row.category.name }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Название"
        width="380"
      ></el-table-column>
      <el-table-column
        prop="slug"
        label="Имя в URL"
        width="380"
      ></el-table-column>
      <el-table-column label="Активный">
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isActive"
            :class="$style.checkbox"
          />
        </template>
      </el-table-column>
      <el-table-column label="Отображать в каталоге">
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isInMainCatalog"
            :class="$style.checkbox"
          />
        </template>
      </el-table-column>
      <el-table-column label="Рекламный">
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isPromotional"
            :class="$style.checkbox"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <router-link
            :to="
              getRoute({
                route:
                  $options.ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CATALOG,
                params: { id: scope.row.id },
              })
            "
          >
            Каталог
          </router-link>
        </template>
      </el-table-column>

      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :viewLink="
              getRoute({
                route: $options.ADDWINE_ROUTES.CATALOG.RANGES,
                params: { id: scope.row.slug },
                site: $options.PROJECTS.ADDWINE,
              })
            "
            :edit-link="
              getRoute({
                route:
                  $options.ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.ASSORTMENT,
                params: { id: scope.row.id },
              })
            "
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import notifications from '@/mixins/notifications'

import {
  ADDWINE_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  getRoute,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

export default {
  components: {
    ActionButtons,
    Autocomplete,
  },
  ADDWINE_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  PROJECTS,
  mixins: [notifications],
  data() {
    return {
      assortments: [],
      total: 0,
      page: 1,
      limit: 20,
      search: '',
      categories: [],
      categoryId: '',
      isPromotional: false,
      isActive: false,
      isInCatalog: false,
    }
  },
  created() {
    this.getList(), this.getTree()
  },

  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList({
          limit: this.limit,
          page: this.page,
          search: this.search,
          orderDir: 'asc',
          orderBy: 'category_id',
          categoryId: this.categoryId,
          isPromotional: this.isPromotional,
          isActive: this.isActive,
          isInCatalog: this.isInCatalog,
        })

      loading.close()

      if (error) return

      this.assortments = value.data
      this.total = value.meta.count

      if (!this.assortments.length) {
        this.showNotification(
          'К сожалению, у данной категории нет ассортиментов',
          'error',
        )
      }
    },
    async remove(assortment) {
      const isConfirm = confirm(
        `Вы точно хотите удалить ассортимент: ${assortment.name}?`,
      )
      if (!isConfirm) {
        return
      }
      const { error } = await delivery.ProductsCore.AssortmentsActions.delete(
        assortment.id,
      )
      if (!error) {
        this.showNotification('Ассортимент успешно удалён', 'success')
        await this.getList()
      }
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
    async handleFindAssortments() {
      await this.getList()
    },
    handleSelectAssortment(selected) {
      this.$router.push(
        getRoute({
          route: ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.ASSORTMENT,
          params: { id: selected.id },
        }),
      )
    },
    getRoute({ route, site, params }) {
      return getRoute({ route, site, params })
    },
    async getTree() {
      const { value, error } =
        await delivery.ProductsCore.CategoriesActions.getTree()

      if (error) return

      this.categories = value.children
    },
  },
}
</script>

<style lang="scss" module>
.assortments {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;

    .search {
      display: flex;
      align-items: center;

      input {
        border-radius: 0;
      }
    }
    .button {
      border-radius: 0;
    }

    .createButton {
      display: flex;
      justify-content: flex-end;
      flex: auto;
    }

    .checkBoxWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      margin-left: 1rem;
      cursor: pointer;

      & > label {
        @include text;
      }
    }
  }

  .pagination {
    @include pagination;
  }

  .catalogLink {
    color: inherit;
  }

  .buttons {
    align-items: center;
  }
}

.checkbox {
  pointer-events: none;
}
</style>
